var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } },
      [
        _c(
          "div",
          {
            style: {
              "overflow-y":
                _vm.items.length > (_vm.communication_types.length || 5)
                  ? "auto"
                  : "visible",
            },
          },
          [
            _c("b-table", {
              attrs: {
                busy: _vm.isLoading,
                "head-variant": "light",
                "no-local-sorting": true,
                items: _vm.items,
                fields: _vm.getAllFields,
                "current-page": _vm.currentPage,
                "per-page": "0",
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
                "sticky-header": _vm.height,
                "no-border-collapse": "",
                responsive: "",
                "show-empty": "",
                "empty-text": "Non ci sono informazioni da mostrare",
                small: "",
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
                "sort-changed": _vm.sortingChanged,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("Operazione in corso...")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.hasChecks && !_vm.isCheckExclusive && _vm.items.length
                    ? {
                        key: "head(check)",
                        fn: function (data) {
                          return [
                            _c("base-checkbox", {
                              staticClass: "check",
                              attrs: {
                                name: "all",
                                groupLabel: "",
                                label: data.label,
                              },
                              on: {
                                change: function ($event) {
                                  _vm.$emit("interaction")
                                  _vm.onSelectAllRows()
                                },
                              },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(customer)",
                        fn: function ({ item }) {
                          return [
                            !Array.isArray(item.attributables) &&
                            !item.attributables.length
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.status_registry.value === 1
                                        ? item.attributables.CNAM
                                        : item.attributables.SURN +
                                            " " +
                                            item.attributables.NAME
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(code)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.registry_groups
                                    ? item.registry_groups
                                        .map((e) => e.code)
                                        .join(", ")
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(status_registry)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.status_registry.value === 1 ? "G" : "F"
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(insurance_policy)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "span",
                              {
                                class: {
                                  "text-red": !item.insurance_policies.length,
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.insurance_policies.filter(
                                        (insurance_policy) =>
                                          [0, 1, 2].includes(
                                            insurance_policy.status_policy.value
                                          )
                                      ).length
                                        ? item.insurance_policies.filter(
                                            (insurance_policy) =>
                                              [0, 1, 2].includes(
                                                insurance_policy.status_policy
                                                  .value
                                              )
                                          ).length
                                        : "no pol"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(note_counter)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: _vm.toTooltipTask(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ),
                                      "Note"
                                    ),
                                    expression:
                                      "\n              toTooltipTask(\n                item.tasks.filter((task) => task.task_type.value === 2),\n                'Note'\n              )\n            ",
                                  },
                                ],
                                staticClass: "info-box float-left",
                                attrs: {
                                  role: item.tasks.filter(
                                    (task) => task.task_type.value === 2
                                  ).length
                                    ? "button"
                                    : null,
                                },
                                on: {
                                  click: function ($event) {
                                    item.tasks.filter(
                                      (task) => task.task_type.value === 2
                                    ).length
                                      ? _vm.openNoteDetailModal(
                                          item.tasks.filter(
                                            (task) => task.task_type.value === 2
                                          )
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.tasks.filter(
                                        (task) => task.task_type.value === 2
                                      ).length
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(broker)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.insurance_policies
                                    ? item.insurance_policies
                                        .filter((e) => {
                                          return (
                                            e.broker &&
                                            e.broker.status_broker.value === 0
                                          )
                                        })
                                        // filtrare solo quelle che si trovano in stati particolari:
                                        // 0: non perfezionata,
                                        // 1: perfezionata
                                        // 2:sospesa
                                        .filter((e) => {
                                          return (
                                            e.status_policy &&
                                            [0, 1, 2].includes(
                                              e.status_policy.value
                                            )
                                          )
                                        })
                                        .map((e) => e.broker.attributables.BUID)
                                        .filter(
                                          (value, index, e) =>
                                            e.indexOf(value) === index
                                        )
                                        .join(", ")
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(document_counter)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: _vm.toTooltipTask(
                                      item.documents,
                                      "Documenti",
                                      [
                                        { label: "Titoli", key: "title" },
                                        { label: "Descrizione", key: "note" },
                                        {
                                          label: "Ultimo aggiornamento",
                                          key: "last_update",
                                        },
                                      ]
                                    ),
                                    expression:
                                      "\n              toTooltipTask(item.documents, 'Documenti', [\n                { label: 'Titoli', key: 'title' },\n                { label: 'Descrizione', key: 'note' },\n                { label: 'Ultimo aggiornamento', key: 'last_update' },\n              ])\n            ",
                                  },
                                ],
                                staticClass: "info-box float-left",
                                attrs: {
                                  role: item.documents.length ? "button" : null,
                                },
                                on: {
                                  click: function ($event) {
                                    item.documents.length
                                      ? _vm.openDocumentDetailModal(
                                          item.documents
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.documents.length) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(primary_group)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.registry_groups
                                    ? item.registry_groups.length &&
                                      item.registry_groups.find(
                                        (registry_group) =>
                                          registry_group.pivot.primary.value ===
                                          "Y"
                                      )
                                      ? item.registry_groups.find(
                                          (registry_group) =>
                                            registry_group.pivot.primary
                                              .value === "Y"
                                        ).code
                                      : ""
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(is_coordinator)",
                        fn: function ({ item }) {
                          return [
                            _c("base-checkbox", {
                              attrs: {
                                name: "is_coordinator",
                                chk_val: "Y",
                                unchk_val: "N",
                              },
                              on: {
                                input: function ($event) {
                                  _vm.setIsCoordinator(
                                    item.registry_groups[
                                      _vm.getRegistryGroupIndex(
                                        item.registry_groups
                                      )
                                    ].pivot
                                  )
                                },
                              },
                              model: {
                                value:
                                  item.registry_groups[
                                    _vm.getRegistryGroupIndex(
                                      item.registry_groups
                                    )
                                  ].pivot.is_coordinator.value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.registry_groups[
                                      _vm.getRegistryGroupIndex(
                                        item.registry_groups
                                      )
                                    ].pivot.is_coordinator,
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              item.registry_groups[\n                getRegistryGroupIndex(item.registry_groups)\n              ].pivot.is_coordinator.value\n            ",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(communication_type)",
                        fn: function ({ item }) {
                          return [
                            _c("base-select", {
                              attrs: {
                                label: "",
                                name: "communication_type",
                                options: _vm.communication_types,
                              },
                              on: {
                                select: _vm.setCommunicationType,
                                remove: function ($event) {
                                  return _vm.$emit("interaction")
                                },
                              },
                              model: {
                                value:
                                  item.registry_groups[
                                    _vm.getRegistryGroupIndex(
                                      item.registry_groups
                                    )
                                  ].pivot.communication_type.value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.registry_groups[
                                      _vm.getRegistryGroupIndex(
                                        item.registry_groups
                                      )
                                    ].pivot.communication_type,
                                    "value",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n              item.registry_groups[\n                getRegistryGroupIndex(item.registry_groups)\n              ].pivot.communication_type.value\n            ",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  {
                    key: "cell(address)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toInfoData(item, "address", 0)
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(domicilio)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "correspondences",
                                item.correspondences,
                                ["ADDR"],
                                [1]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(phon)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "address_books",
                                item.address_books,
                                ["PHON"],
                                [0]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(mobile)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "address_books",
                                item.address_books,
                                ["CELL"],
                                [0]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(email1)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "address_books",
                                item.address_books,
                                ["MAIL"],
                                [0]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(email2)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "address_books",
                                item.address_books,
                                ["MAIL"],
                                [1]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(pec)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.toRelationString(
                                "address_books",
                                item.address_books,
                                ["PEC"],
                                [0]
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                  _vm.items.length
                    ? {
                        key: "cell(relative_types)",
                        fn: function ({ item }) {
                          return [
                            _c("base-select", {
                              attrs: {
                                label: "",
                                name: "relation_types",
                                options: _vm.getFilteredRelativeTypes(item),
                              },
                              model: {
                                value: item["relation_type_id"],
                                callback: function ($$v) {
                                  _vm.$set(item, "relation_type_id", $$v)
                                },
                                expression: "item['relation_type_id']",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(relation)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  item.status_registry.value === 1
                                    ? item.pivot.relative_type.male
                                    : item.attributables.SEX === "M"
                                    ? item.pivot.relative_type.male
                                    : item.pivot.relative_type.female
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "action-buttons" },
                          [
                            _vm.actions.includes("infomodal")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Modale Dettagli",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openModal(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      },
                                    },
                                  },
                                  [_c("b-icon", { attrs: { icon: "eye" } })],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("details")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: row.detailsShowing
                                        ? "Chiudi dettagli"
                                        : "Apri dettagli",
                                    },
                                    on: { click: row.toggleDetails },
                                  },
                                  [
                                    row.detailsShowing
                                      ? _c("b-icon", {
                                          attrs: { icon: "chevron-up" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "chevron-down" },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("edit")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Modifica",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(row.item.id, row.item)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "pencil-square" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("destroy")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Elimina",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDestroy(row.item.id)
                                      },
                                    },
                                  },
                                  [_c("b-icon", { attrs: { icon: "trash" } })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(rowSelector)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "action-buttons" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  title: "Seleziona",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChosen(row.item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Seleziona\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "row-details",
                    fn: function (row) {
                      return [
                        _c(
                          "b-card",
                          _vm._l(
                            _vm.customized(row.item),
                            function (value, key) {
                              return _c(
                                "b-row",
                                { key: key, staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-sm-right",
                                      attrs: { sm: "3" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm._f("capitalize")(key)) +
                                            ":"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-col", [_vm._v(_vm._s(value))]),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.noPagination
          ? _c("b-row", [
              _c("div", { staticClass: "show-text" }, [
                _c("p", [_vm._v("Mostra")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "per-page-select",
                        "label-cols-sm": "3",
                        "label-cols-md": "",
                        "label-cols-lg": "3",
                        "label-size": "sm",
                        md: "auto",
                      },
                    },
                    [
                      _c("b-form-select", {
                        staticStyle: { width: "68px" },
                        attrs: {
                          id: "per-page-select",
                          options: _vm.pageOptions,
                          size: "sm",
                          disabled: !_vm.items.length,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onPageChange(1)
                          },
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page" }, [
                _c("p", [
                  _vm._v("di " + _vm._s(_vm.totalItems) + " per pagina"),
                ]),
              ]),
              _vm._v(" "),
              _vm.totalItems
                ? _c(
                    "div",
                    { staticClass: "pagination" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          limit: "3",
                          "total-rows": _vm.totalItems,
                          "per-page": _vm.perPage,
                          size: "sm",
                        },
                        on: { change: _vm.onPageChange },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.totalItems && !_vm.noInputPaginator
                ? _c(
                    "div",
                    { staticClass: "input_pagination" },
                    [
                      _c("base-input-pagination", {
                        on: { pagination: _vm.onInputPagination },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.infomodalName,
              title: _vm.modalTitle,
              size: "xl",
              "ok-only": "",
              "ok-variant": "lisaweb",
              "header-bg-variant": "lisaweb",
              "no-close-on-backdrop": "",
            },
            on: { hidden: _vm.onModalHidden },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "pt-1", lazy: "" },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        attrs: {
                          active: index === _vm.tabIndex,
                          title: tab.text,
                          "title-item-class": "lisaweb",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTab(tab, index)
                          },
                        },
                      },
                      [
                        _c("button-group-tab"),
                        _vm._v(" "),
                        _c(
                          "keep-alive",
                          [
                            _vm.selected === tab.name
                              ? _c(_vm.selected, {
                                  tag: "component",
                                  attrs: { resourceId: _vm.modalResourceId },
                                  on: { fetch: _vm.fetch },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }