var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "div",
        { staticClass: "mb-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-create",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        size: "sm",
                        title: "Associa relazione all'anagrafica",
                      },
                      on: { click: _vm.openAddRelationModal },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "plus-circle", "aria-hidden": "true" },
                      }),
                      _vm._v(" Aggiungi\n          relazione"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("registry-relations", {
        ref: _vm.tableRef,
        attrs: {
          noCustom: "",
          noPagination: "",
          hasChecks: "",
          fields: _vm.fields,
          repository: "registry",
          resource: "registries",
          filterName: _vm.filterName,
          noInnerWidth: "",
          onlyActions: ["destroy"],
        },
        on: { destroy: _vm.onDelete },
      }),
      _vm._v(" "),
      _c("add-relation-modal", {
        attrs: {
          fields: _vm.fields,
          resourceId: _vm.resourceId,
          excludedRegistryIds: _vm.excludedRegistryIds,
        },
        on: { saveRelation: _vm.onAdd },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }